@import "../logofonts/mulish/mulish.css";
@import "../logofonts/PlusJakartaSans/PlusJakartaSans.css";
@import "../logofonts/poppins/poppins.css";
@import "../logofonts/roboto/roboto.css";
@import "../logofonts/inter/inter.css";
@import "../logofonts/merriweather/merriweather.css";
@import "../logofonts/raleway/raleway.css";
@import "../logofonts/montserrat/montserrat.css";
@import "../logofonts/RobotSlab/RobotoSlab.css";

html {
    scroll-behavior: smooth;
    scroll-padding-top: 60px;
}
* {
    font-feature-settings: normal !important;
    font-variation-settings: normal !important;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

:root {
    --brand-color: #1642c5;
    ---brand-color-button: #1642c5;
    --brand-text-color: #ffffff;
    --content-background-color: #ffffff;
    --main-text-color: #060606;
    --secondary-txt-color: #757575;
    --cards-color: "0px 2px 33px 0px rgba(0, 0, 0, 0.05)";
    --button-color: #1642c5;
    --icon-color: var(--button-color);
    --accent-color: "";
    --theme-color: #31376c;
    --brand-font-family: "inter";
    --logo-font-weight: "600";
    --brand-border-radius: "8px";
    --border-color-primary: "#E3E7EF";
    --brand-border-radius-secoundary: "8px";
    --textGrey: #8c9cbb;
    --main-background-color: "#F8F8F8";
    --container-background-color: "#FFFFFF";
    --map-pin-border-color: "#D1D2D4";
    --map-pin-background-color: "#BDBDBD";
    --step-content-background-color: "#F7F7F8";
    --box-shadow-color: "#e0eaff";
    --template-background-color: "#FAF8F7";
    --template-text-color: "#060606";
    --template-card-background-color: "#000000";
    --template-card-border-color: "#E0E0E0";
    --brand-navigation-color-hover: "#000";
    --brand-color-LightDark: "#000";
    --logo-font-size: 18px;
    --tooltip-bg: "#000";
    --shadow-background-color: "rgb(255,255,255)";
    --custom-loading-background-color: "#eaecf0";
    --text-more-dark: "#101828";
    --input-shadow: "#1018280f";
    --booking-card-shadow: "#AAAAAA26";
    --header-shadow: "#0000000D";
}

.inter {
    font-family: "inter";
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.merriweather {
    font-family: "merriweather";
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.roboto {
    font-family: "roboto";
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.montserrat {
    font-family: "montserrat";
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.roboto_slab {
    font-family: "roboto_slab";
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.poppins {
    font-family: "poppins";
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.mulish {
    font-family: "mulish";
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.raleway {
    font-family: "raleway";
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.plus_jakarta_sans {
    font-family: "plus_jakarta_sans" !important;
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.font-logo {
    font-feature-settings: normal;
    font-variation-settings: normal;
}
.font-primary {
    font-feature-settings: normal;
    font-variation-settings: normal;
}

#root {
    display: flex;
    flex-direction: column;
    flex: 1;
}

html,
body {
    font-family: var(--brand-font-family);
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
}
@media (min-width: 280px) and (max-width: 1023px) {
    html,
    body {
        height: 100%;
        min-height: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden !important;
    }
    .main-wrapper {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
        height: 100%;
        overflow: hidden;
        flex: 1;
    }
}
.main-wrapper {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    height: 100%;
    overflow: hidden;
    flex: 1;
}

* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    -webkit-user-drag: none;
}

body {
    margin: 0;
    font-family: var(--brand-font-family);
    -webkit-font-smoothing: antialiased;
    font-kerning: auto;
    font-optical-sizing: auto;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    color: var(--black);
    outline: none;
    background-color: var(--content-background-color);
    min-height: 100%;
    max-width: 100vw;
    font-size: 100%;
    line-height: 1;
    font-weight: 400;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    -webkit-user-drag: none;
    touch-action: none !important;
    -ms-touch-action: none !important;
    touch-action: manipulation;
}

/* width */
::-webkit-scrollbar {
    width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #d9d9d9;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.app_layout {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
}

.text-secondary-appointment {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: normal;
    font-variation-settings: normal;
}

.fl-btn {
    height: 50px;
}

.fl-dropdown-shadow {
    box-shadow: 0px 5.33333px 16px 0px rgba(146, 140, 151, 0.25);
}

.flr_select_box .select__control {
    border: none;
    border-radius: 24px;
    height: 43px;
    cursor: pointer;
    background-color: var(--bg-contentBackground);
    box-shadow: none;
    border: 1px solid var(--secondary-txt-color) !important;
}

.flr_select_box .select__control {
    padding: 0px 0px 0px 0px;
    color: var(--main-text-color);
    line-height: 23px;
    text-transform: uppercase;
    background-color: transparent;
    font-weight: 400;
    font-size: 14px !important;
    font-size: 14px;
    border: 1px solid var(--secondary-txt-color) !important;
}

.flr_select_box .select__control:focus {
    border: 1px solid var(--secondary-txt-color) !important;
}

.flr_select_box .select__control:hover {
    border: 1px solid var(--secondary-txt-color) !important;
}

.flr_select_box .select__control:focus-visible {
    border: 1px solid var(--secondary-txt-color) !important;
}

.flr_select_box .select__control:focus-within {
    border: 1px solid var(--secondary-txt-color) !important;
}

.flr_select_box .select__control .select__placeholder,
.select__control .select__single-value {
    color: var(--main-text-color) !important;
    font-size: 12px !important;
    font-weight: 400 !important;
}

.select__control .select__input-container {
    color: var(--main-text-color);
}

.select__control .select__value-container {
    padding: 0px;
}

.flr_select_box .select__menu {
    border-radius: 12px;
    margin-top: 1px;
    overflow: hidden;
    box-shadow: 0px 5.33333px 16px 0px rgba(146, 140, 151, 0.25);
    cursor: pointer;
    z-index: 30;
    background-color: var(--dropdown-background-color);
    color: var(--main-text-color);
}

.select__menu {
    border-radius: 12px;
    margin-top: 1px;
    overflow: hidden;
    box-shadow: 0px 5.33333px 16px 0px rgba(146, 140, 151, 0.25);
    cursor: pointer;
    z-index: 30;
    background-color: var(--dropdown-background-color);
    color: var(--main-text-color);
}

.flr_select_box .select__menu-list {
    padding: 6px;
}

.select__menu-list {
    padding: 6px;
}

.select__menu-list::-webkit-scrollbar {
    width: 0px !important;
}

.select__menu-list::-webkit-scrollbar-track {
    background: #ebebeb;
}

.select__menu-list::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #c4c4c499;
}

.flr_select_box .select__control .select__value-container .select__input-container .select__input {
    outline: none;
    box-shadow: unset;
}

.select__control .select__value-container .select__input-container .select__input {
    outline: none;
    box-shadow: unset;
}

.flr_select_box.is-invalid {
    border: 0;
    background-color: transparent;
}

.flr_select_box.is-invalid .select__control {
    background-color: #f9e0e0;
    border: 1px solid #e33b3b;
}

.select__control {
    background-color: #f9e0e0;
    border: 1px solid #e33b3b;
}

.flr_select_box.is-invalid .select__control--is-focused {
    outline: none !important;
    box-shadow: unset !important;
    border: none;
}

.flr_select_box .select__option {
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.3s ease;
}

/* .flr_select_box .select__option--is-focused {
    background-color: var(--button-color);
} */
.flr_select_box .select__control--is-focused:hover {
    border: none;
}

.flr_select_box .select__option:hover,
.flr_select_box .select__option--is-selected {
    background-color: var(--secondary-txt-color);
    color: var(--main-text-color);
}

.flr_select_box .select__control .select__indicator-separator {
    display: none;
}

.flr_select_box .select__indicator {
    margin-top: -5px;
    color: var(--main-text-color);
}

.flr_select_box .select__control .select__value-container {
    padding: 8px 2px 4px 14px;
}

.flr_select_box .select__control .select__placeholder {
    color: var(--main-text-color);
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px rgba(239, 241, 245, 0) inset !important;
    -webkit-text-fill-color: #1f293c !important;
} */

.flr_select_box .select__control .select__placeholder {
    color: var(--main-text-color);
}

.btn_primary > span,
.btn_outline_primary > span,
.btn_secondary_black > span {
    font-size: inherit;
    padding: 0;
    gap: 5px;
}

.form_control_payment > .__PrivateStripeElement {
    height: 100%;
    color: green;
}

.text-base,
.text-sm {
    line-height: 150%;
}

.gm-style-iw .gm-style-iw-c {
    padding: 0;
}

.marker-style {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 50;
    transform: translate(-50%, -100%) translate(0px, 0px);
}

.tooltip-bottom::before {
    content: "";
    position: absolute;
    display: block;
    width: 0px;
    left: 50%;
    bottom: 8px;
    border: 12px solid transparent;
    border-bottom: 0;
    border-top: 12px solid #fff;
    box-shadow: 0px 10px 33px rgba(0, 0, 0, 0.15);
    transform: translate(-50%, calc(100% + 5px));
}

.datatable-no-thead .p-datatable-thead {
    display: none;
}

.datatable-no-thead .datatable-no-thead-paginator .p-paginator-current {
    margin-right: auto;
}

.datatable-no-thead .datatable-no-thead-paginator .p-paginator-element {
    min-width: 2rem;
}

.datatable-no-thead .p-component-overlay {
    align-items: flex-start;
    justify-content: flex-start;
    background-color: var(--cards-color);
}

.p-paginator {
    padding: 0.5rem 0;
    background-color: var(--cards-color);
    color: var(--main-text-color);
}

.p-paginator .p-paginator-current {
    color: var(--main-text-color);
}

.gm-style iframe + div {
    border: none !important;
}

.select__single-value {
    color: var(--main-text-color) !important;
}

*,
::before,
::after {
    --tw-border-spacing-x: 0;
    --tw-border-spacing-y: 0;
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    --tw-pan-x: ;
    --tw-pan-y: ;
    --tw-pinch-zoom: ;
    --tw-scroll-snap-strictness: proximity;
    --tw-gradient-from-position: ;
    --tw-gradient-via-position: ;
    --tw-gradient-to-position: ;
    --tw-ordinal: ;
    --tw-slashed-zero: ;
    --tw-numeric-figure: ;
    --tw-numeric-spacing: ;
    --tw-numeric-fraction: ;
    --tw-ring-inset: ;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgb(63 131 248 / 0.5);
    --tw-ring-offset-shadow: 0 0 #0000;
    --tw-ring-shadow: 0 0 #0000;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    --tw-blur: ;
    --tw-brightness: ;
    --tw-contrast: ;
    --tw-grayscale: ;
    --tw-hue-rotate: ;
    --tw-invert: ;
    --tw-saturate: ;
    --tw-sepia: ;
    --tw-drop-shadow: ;
    --tw-backdrop-blur: ;
    --tw-backdrop-brightness: ;
    --tw-backdrop-contrast: ;
    --tw-backdrop-grayscale: ;
    --tw-backdrop-hue-rotate: ;
    --tw-backdrop-invert: ;
    --tw-backdrop-opacity: ;
    --tw-backdrop-saturate: ;
    --tw-backdrop-sepia: ;
}

*,
::before,
::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
}

.p-datatable .p-datatable-tbody > tr {
    background: var(--cards-color);
}

.p-sidebar-header {
    padding-bottom: 0px !important;
}

.bg-filter-blur {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
}

.profile-images {
    overflow: hidden;
    cursor: pointer;
    transition-duration: 400ms;
    transition: opacity 400ms cubic-bezier(0.075, 0, 0.165, 0), transform 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.profile-img {
    transition-duration: 400ms;
}

.profile-images:hover .profile-img {
    border-radius: 50% !important;
    transition-duration: 400ms;
}
.profile-images:hover .profile-img.profile-img-border-radius {
    border-radius: 18px !important;
    transition-duration: 400ms;
}

.navbar-duration {
    transition-delay: 100ms;
    transition-duration: 1000ms;
}

.service-duration {
    transition-delay: 200ms;
    transition-duration: 1000ms;
    transition-timing-function: ease-in-out;
}

.border-b-line:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    border-bottom: solid 2px #000;
    animation: border_anim 3s linear forwards;
}

@keyframes border_anim {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

.border-b-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
    height: 1px;
}

.border-b-animation:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -1px;
    left: 0;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s;
    background-color: var(--main-text-color);
}
.border-b-animation-hover:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
    height: 1px;
}
@media (min-width: 1024px) {
    .border-b-animation-fix:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -1px;
        left: 0;
        transform: scaleX(1);
        transform-origin: bottom left;
        transition: transform 0.3s;
        background-color: var(--brand-navigation-color-hover);
    }
    .border-b-animation-fix-none:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -1px;
        left: 0;
        transform: scaleX(1);
        transform-origin: bottom left;
        transition: transform 0.3s;
        background-color: transparent;
    }
    .border-b-animation-header-hover:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        bottom: -1px;
        left: 0;
        transform: scaleX(0);
        transform-origin: bottom right;
        transition: transform 0.3s;
        background-color: var(--brand-navigation-color-hover);
    }
    .border-b-animation-header-hover:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
        height: 1px;
    }
}
.border-b-animation-hover:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -1px;
    left: 0;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s;
    background-color: var(--brand-color);
}

.border-b-white-animation-hover:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -1px;
    left: 0;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s;
    background-color: #ffffff;
}
.border-b-white-animation-hover:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
    height: 1px;
}

.get-direction .border-b-animation:after {
    content: "";
    background-color: #ffffff;
}
.modelGallery .model-header-btn {
    z-index: 99999;
}

.date-info:nth-child(1),
.date-info:nth-child(2),
.date-info:nth-child(3) {
    padding-right: 25px;
}

.ElementsApp {
    font-size: 14px !important;
    height: 1.2em !important;
    left: 0 !important;
    line-height: 1.2em !important;
    position: absolute !important;
    top: 0 !important;
    width: 100% !important;
}

.ElementsApp,
.ElementsApp .InputElement {
    line-height: 35px !important;
    color: #8c9cbb !important;
    height: 28px !important;
}

.InputElement::placeholder {
    color: red;
}
.InputContainer input::placeholder {
    color: #fff; /* Change this to your desired color */
    opacity: 1; /* Ensures full visibility */
}

/* For better cross-browser support */
.InputContainer::-webkit-input-placeholder {
    color: #fff;
}
.InputContainer:-moz-placeholder {
    color: #fff;
}
.InputContainer::-moz-placeholder {
    color: #fff;
}
.InputContainer:-ms-input-placeholder {
    color: #fff;
}
.ElementsApp input {
    background-color: transparent !important;
    border: none !important;
    display: block !important;
    font-family: sans-serif !important;
    font-size: 1em !important;
    height: 1.2em !important;
    line-height: 1.2em !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
}

.InputContainer .InputElement {
    position: absolute !important;
    top: -4px !important;
}

.scrollbar_add::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    cursor: pointer;
}

.scrollbar_add::-webkit-scrollbar-track {
    background: #ebebeb;
}

.scrollbar_add::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #c4c4c499;
}

.product_grid {
    display: grid;
    grid-auto-columns: max-content;
    grid-template-rows: 1fr;
    width: 100%;
    overflow-x: auto;
}

.product_grid_child {
    grid-row: 1;
    grid-column: auto;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-animation: autofill 0s forwards;
    animation: autofill 0s forwards;
}

@-webkit-keyframes autofill {
    100% {
        background: transparent;
        color: inherit;
    }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-animation: autofill 0s forwards;
    animation: autofill 0s forwards;
}

input,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 50px rgba(255, 255, 255, 0) inset !important;
    background-color: transparent !important;
    background-clip: text;
}
.sidebar-wrapper .p-sidebar-header {
    height: 0px;
    position: relative;
}
@media (min-width: 1024px) {
    .sidebar-wrapper .p-sidebar-header {
        padding: 0px;
    }
}

.p-sidebar-icons:focus,
.p-sidebar-icons:focus-visible,
.p-sidebar-icons:focus-within,
.p-sidebar-icons:active {
    outline: none;
    box-shadow: unset;
}
.p-sidebar-close:focus,
.p-sidebar-close:focus-visible,
.p-sidebar-close:focus-within,
.p-sidebar-close:active {
    outline: none;
    box-shadow: unset;
}
.sidebar-wrapper .p-sidebar-header .p-sidebar-icons {
    background-color: transparent;
    border-radius: 100%;
    height: 32px;
    padding: 7px;
    width: 32px;
    display: flex;
    justify-content: center;
    color: #8c9cbb;
    align-items: center;
}
.sidebar-wrapper .p-sidebar-header .p-sidebar-icons:hover {
    background-color: var(--main-background-color);
    color: var(--main-text-color);
    border-radius: 100%;
    height: 32px;
    padding: 6px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.RegularProfileModel.sidebar-wrapper .p-sidebar-header .p-sidebar-icons {
    display: none;
}
.SuccessModel.mobile-login-modal.sidebar-wrapper .p-sidebar-header .p-sidebar-icons {
    display: none !important;
}
.profile-sidebar.sidebar-wrapper .p-sidebar-header .p-sidebar-icons:hover {
    display: none !important;
    background-color: transparent;
    border-radius: 100%;
    height: 32px;
    padding: 6px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.profile-sidebar.sidebar-wrapper .p-sidebar-header .p-sidebar-icons {
    display: none !important;
    position: absolute;
    bottom: -23px;
    color: var(--main-text-color);
    border-radius: 100%;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    outline: none;
}
@media (min-width: 280px) and (max-width: 1024px) {
    .profile-sidebar .p-sidebar-close {
        display: none;
    }
    .sidebar-wrapper .p-sidebar-header .p-sidebar-icons {
        position: absolute;
        bottom: -30px !important;
        right: 16px;
        z-index: 999999;
        color: #8c9cbb;
        border-radius: 100%;
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px;
        outline: none;
    }
    .profile-sidebar.sidebar-wrapper .p-sidebar-header .p-sidebar-icons:hover {
        display: none !important;
        background-color: transparent;
        border-radius: 100%;
        height: 32px;
        padding: 6px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .profile-sidebar.sidebar-wrapper .p-sidebar-header .p-sidebar-icons {
        display: none !important;
        position: absolute;
        bottom: -23px;
        color: var(--main-text-color);
        border-radius: 100%;
        height: 32px;
        width: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px;
        outline: none;
    }
}
.sidebar-wrapper .p-sidebar-content {
    padding-top: 8px;
    padding-left: 24px;
    padding-right: 24px;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.remove-scroll-sidebar .sidebar-wrapper .p-sidebar-content {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.AppointmentPreview.sidebar-wrapper .p-sidebar-content {
    padding-top: 8px;
    padding-left: 0px;
    padding-right: 0px;
}
.noShowPolicy.sidebar-wrapper .p-sidebar-content {
    padding-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
}
.mobile-login-modal.sidebar-wrapper .p-sidebar-content {
    padding: 0px;
}
.mobileCroperModal.sidebar-wrapper .p-sidebar-content {
    padding: 0px;
}
.profile-sidebar-pages.sidebar-wrapper .p-sidebar-content {
    padding-left: 0px;
    padding-right: 0px;
}
.profile-sidebar.profile-sidebar-pages.sidebar-wrapper .p-sidebar-content {
    padding-left: 0px;
    padding-right: 0px;
}

.profile-sidebar.sidebar-wrapper .p-sidebar-content {
    padding-top: 0px;
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.profile-sidebar {
    border-radius: 0px !important;
}
.profile-sidebar .p-sidebar-header {
    padding: 0px;
}

.sidebar-wrapper .p-sidebar-header::after {
    content: "";
    display: flex;
    height: 4px;
    background-color: var(--textGrey);
    width: 48px;
    opacity: 0.2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 10px;
    border-radius: 20px;
}
.profile-sidebar.sidebar-wrapper .p-sidebar-header::after {
    content: "";
    display: none;
}
@media (min-width: 1024px) {
    .top-line-remove.sidebar-wrapper .p-sidebar-header::after {
        content: "";
        display: none;
    }
}
.sidebar-wrapper .p-sidebar-header .p-sidebar-icons {
    position: absolute;
    bottom: -39px;
    right: 16px;
    margin-top: 12px;
    z-index: 999999;
    color: #8c9cbb;
    border-radius: 100%;
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    outline: none;
}
@media (min-width: 280px) and (max-width: 1024px) {
    .profile-sidebar-pages.sidebar-wrapper .p-sidebar-content {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.dropdown-item-wrapper .item-icon-svg {
    color: var(--textGrey);
}
.dropdown-item-wrapper .item-text {
    color: var(--main-text-color);
}
.dropdown-item-wrapper:hover .item-text {
    color: var(--brand-color);
}
div[data-testid="flowbite-tooltip-arrow"] {
    background-color: var(--tooltip-bg) !important;
    width: 16px;
    height: 16px;
    border: 0;
    position: absolute !important;
    z-index: 10 !important;
    transform: rotate(45deg) !important;
}
.special_button {
    background-color: rgba(var(--brand-color), 0.2) !important;
}
.capitalize-first::first-letter {
    text-transform: uppercase;
}
.mapboxgl-ctrl {
    display: none !important;
}

.custom-range-slider input {
    width: 100%;
}

.imageCropper .cropper-view-box,
.imageCropper .cropper-face {
    border-radius: 50%;
}

/* The css styles for `outline` do not follow `border-radius` on iOS/Safari (#979). */
.imageCropper .cropper-view-box {
    outline: 0;
    box-shadow: 0 0 0 1px #39f;
}

input[type="range"]::-moz-range-thumb {
    width: 16px;
    height: 16px;
    height: 6px;
    background: #007bff;
    border-radius: 50%;
    border: 2px solid #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background 0.2s, transform 0.1s;
}

input[type="range"]::-moz-range-thumb:active {
    background: #0056b3;
    transform: scale(1.1);
}

input[type="range"]::-moz-range-track {
    width: 100%;
    background: #ddd;
    border-radius: 3px;
}

.main_slider .swiper-pagination {
    display: flex;
    justify-content: flex-end;
    bottom: 36px !important;
    padding-right: 60px;
}
.main_slider .swiper-pagination-bullet {
    color: transparent;
    width: 10px;
    height: 10px;
    background-color: white;
    opacity: 0.3;
    transition: all 0.5s ease-in-out;
    border-radius: 6px;
}
.main_slider .swiper-pagination-bullet-active {
    opacity: 1;
    width: 54px;
}

.cropper-modal {
    background-color: black !important;
    opacity: 0.5 !important;
}
/* .cropper-crop-box {
    width: 310px !important;
    height: 310px !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
} */
.cropper-crop-box .cropper-view-box {
    box-shadow: none;
    outline: 2px solid var(--border-color-hover);
    outline-color: var(--border-color-hover);
}

.app-logo-img-block > div {
    height: 100% !important;
}

.cropper-container {
    -webkit-touch-callout: none;
    direction: ltr;
    font-size: 0;
    line-height: 0;
    position: relative;
    touch-action: none;
    -webkit-user-select: none;
    user-select: none;
}

.cropper-canvas,
.cropper-wrap-box {
    overflow: hidden;
}

.cropper-modal {
    background-color: #000 !important;
    opacity: 0.5 !important;
}

.cropper-move {
    cursor: move;
}
.booking-container-wrapper .booking-container {
    overflow: visible !important;
    position: relative;
    margin-top: 35px;
}
.booking-container .slick-dots {
    bottom: -19px;
}
.booking-container .slick-dots li {
    position: relative;
    display: inline-block;
    width: 17px;
    height: 20px;
    margin: 0 0px;
    padding: 0;
    cursor: pointer;
}
@media (min-width: 280px) and (max-width: 1023px) {
    .menu_wrapper {
        min-width: calc(100% - 32px);
        height: calc(100vh - 150px);
        max-height: calc(100vh - 72px);
        position: absolute;
        top: 68px;
        border: 1px solid var(--border-color-primary);
        border-radius: 8px;
        margin: 0px 8px;
        z-index: 9;
        position: absolute;
        background: var(--container-background-color);
        /* overflow-y: unset; */
        /* overflow-x: hidden; */
    }

    .menu_wrapper::-webkit-scrollbar {
        width: 0px;
        display: none;
    }
    .menu_wrapper::-webkit-scrollbar-track {
        background: #ebebeb;
        display: none;
    }
    .menu_wrapper::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: #c4c4c499;
        display: none;
    }

    .getAppHeader .menu_wrapper {
        height: calc(100vh - 80px) !important;
        max-height: calc(100vh - 80px) !important;
    }
    .fullScreenHeader .menu_wrapper {
        height: calc(100vh - 98px) !important;
        max-height: calc(100vh - 98px) !important;
    }
    .menu_wrapper:after {
        content: "";
        height: calc(100vh - 130px);
        position: absolute;
        min-width: 100%;
        z-index: -9;
        left: -9px;
        right: -9px;
        top: -2px;
        bottom: 0;
        background: var(--main-background-color);
    }
    .getAppHeader .menu_wrapper:after {
        content: "";
        height: calc(100vh - 58px) !important;
    }
    .fullScreenHeader .menu_wrapper:after {
        content: "";
        height: calc(100vh - 68px) !important;
    }
    .menu_wrapper::before {
        content: "";
        height: calc(100dvh - 150px);
        max-height: calc(100dvh - 150px);
        min-width: calc(100% - 32px);
        position: absolute;
        z-index: -1;
        left: 0px;
        right: 0px;
        top: 8px;
        bottom: 0;
        background: var(--container-background-color);
        border: 1px solid var(--border-color-primary);
        border-radius: 8px;
    }
    .getAppHeader .menu_wrapper::before {
        content: "";
        max-height: calc(100dvh - 80px) !important;
        height: calc(100dvh - 80px) !important;
    }
    .fullScreenHeader .menu_wrapper::before {
        content: "";
        max-height: calc(100dvh - 98px) !important;
        height: calc(100dvh - 98px) !important;
    }
}
.left_sidebar_content::-webkit-scrollbar {
    width: 3px;
}
.left_sidebar_content::-webkit-scrollbar-track {
    background: var(--main-background-color);
}
.left_sidebar_content::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: var(--border-color-primary);
}
.left_sidebar_content::-webkit-scrollbar:hover .left_sidebar_content::-webkit-scrollbar-thumb {
    background: var(--brand-color);
}

.fl-tab-btn-view4 {
    @apply p-1 bg-mainBackgroundColor rounded-[10px] overflow-auto text-nowrap border border-borderColorPrimary h-[44px] box-border cursor-pointer justify-start items-center gap-1 flex sm:w-auto w-full;
}
.fl-tab-btn-view4 .fl-tab-link4 {
    @apply sm:basis-auto basis-full px-3 h-[34px] hover:bg-sidebarBgColor hover:text-textCounterColor hover:font-semibold rounded-md box-border  hover:shadow justify-center items-center gap-2  font-semibold flex text-[#98a2b3] text-[14px] leading-[20px];
}
.fl-tab-btn-view4 .fl-tab-link4.active {
    @apply bg-sidebarBgColor rounded-md font-semibold  shadow text-txtcolor;
}

.custom_loading_wrapper {
    width: 80px;
    margin-left: 8px;
    min-height: 5px;
}
@media (min-width: 280px) and (max-width: 1024px) {
    .custom_loading_wrapper {
        width: 80px;
        margin-left: 16px;
        min-height: 5px;
    }
}
.custom_loading {
    width: 100%;
    height: 5px;
    border-radius: 5px;
    animation: 1s linear 0s infinite normal none running dhaMPr;
    background-color: rgba(202, 202, 202, 0.5);
}

@keyframes dhaMPr {
    0% {
        transform: scaleX(1);
        transform-origin: left center;
    }
    25% {
        transform: scaleX(0.3);
        transform-origin: left center;
    }
    50% {
        transform: scaleX(1);
        transform-origin: left center;
    }
    50.1% {
        transform: scaleX(1);
        transform-origin: right center;
    }
    75% {
        transform: scaleX(0.3);
        transform-origin: right center;
    }
    100% {
        transform: scaleX(1);
        transform-origin: right center;
    }
}
.custom-ProgressSpinner .p-progress-spinner-circle {
    stroke: var(--textGrey) !important;
}
.radio-loader .p-progress-spinner-circle {
    stroke: var(--brand-color) !important;
}
.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
    align-self: center;
    border-bottom-color: var(--card-background-color) !important;
    border-top: none;
}
.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
    border-top-color: var(--card-background-color) !important;
}

.flowbite_input input {
    border: none;
    height: 54px;
    padding: 15px 14px;
}
.flowbite_input input {
    border: 1px solid var(--border-counter);
    height: 54px;
    padding: 15px 14px 15px 14px;
    border-radius: var(--brand-border-radius-secoundary);
    box-shadow: 0px 1px 2px 0px #1018280d;
    font-size: 14px;
    color: var(--main-text-color);
    /* transition: transform 0.3s ease, opacity 0.3s ease; */
    /* transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all; */
}
.flowbite_input input.is-error {
    border: 1px solid #e33b3b !important;
}
.flowbite_input input.flowbite_input_focus {
    border: 1px solid var(--brand-color);
    height: 54px;
    padding: 21px 14px 9px 14px;
    /* transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all; */
}
.flowbite_input input.isPhoneNumber {
    padding: 16px 14px 15px 105px;
}
.flowbite_input input.isPhoneNumber.flowbite_input_focus {
    padding: 22px 14px 9px 105px;
}

.phoneNumberOne .flowbite_input input.isPhoneNumber {
    padding: 16px 14px 15px 100px;
}
.phoneNumberOne .flowbite_input input.isPhoneNumber.flowbite_input_focus {
    padding: 22px 14px 9px 100px;
}

.phoneNumberTwo .flowbite_input input.isPhoneNumber {
    padding: 16px 14px 15px 110px;
}
.phoneNumberTwo .flowbite_input input.isPhoneNumber.flowbite_input_focus {
    padding: 22px 14px 9px 110px;
}

.phoneNumberThree .flowbite_input input.isPhoneNumber {
    padding: 16px 14px 15px 118px;
}
.phoneNumberThree .flowbite_input input.isPhoneNumber.flowbite_input_focus {
    padding: 22px 14px 9px 118px;
}
.phoneNumberFour .flowbite_input input.isPhoneNumber {
    padding: 16px 14px 15px 125px;
}
.phoneNumberFour .flowbite_input input.isPhoneNumber.flowbite_input_focus {
    padding: 22px 14px 9px 125px;
}

.phoneNumberWrapper .phoneNumberInner {
    position: absolute;
    left: 14px;
    top: 14px;
}
.phoneNumberWrapper .phoneNumberInner.flowbite_input_focus {
    position: absolute;
    left: 14px;
    top: 20px;
}

.ElementsApp,
.ElementsApp .InputElement {
    color: var(--template-text-color) !important;
}

.flowbite_input label.flowbite_input_focus {
    border: none !important;
    color: var(--textGrey) !important;
}
.flowbite_input p {
    border: none !important;
    margin: 0px !important;
    padding: 0px !important;
}
.flowbite_input label {
    border: none !important;
    color: var(--textGrey);
    font-size: 14px !important;
    font-weight: 400;
    line-height: 24px;
    left: 14px;
    position: absolute;
    top: calc(29%);
    position: absolute;
    left: 14px;
    opacity: 1;
    /* transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease; */
    pointer-events: none;
}
.flowbite_input label.flowbite_input_focus {
    color: var(--textGrey) !important;
    top: calc(50% - 3px);
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 14.52px !important;
    left: 14px;
    opacity: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
}

/* card */
.card-number-wrapper .card-number-input {
    border: none;
    height: 54px;
    padding: 15px 14px;
}
.card-number-wrapper .card-number-input {
    border: 1px solid var(--border-counter);
    height: 54px;
    padding: 18px 14px 18px 14px;
    border-radius: var(--brand-border-radius-secoundary);
    box-shadow: 0px 1px 2px 0px #1018280d;
    font-size: 14px;
    line-height: 24px;
    color: var(--main-text-color);
    align-items: center;
}
.card-number-wrapper .card-number-input.card-number-input-focus {
    border: 1px solid var(--brand-color);
    height: 54px;
    align-items: center;
    padding: 21px 14px 18px 14px;
    /* transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all; */
}
.card-number-wrapper .card-number-input.is-invalid {
    border: 1px solid #e33b3b !important;
    /* transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all; */
}
.card-number-wrapper .card-number-label {
    border: none !important;
    color: var(--textGrey);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    left: 14px;
    position: absolute;
    top: calc(29%);
    position: absolute;
    left: 14px;
    opacity: 1;
    /* transition: top 0.2s ease, font-size 0.2s ease, color 0.2s ease; */
    pointer-events: none;
}
.card-number-wrapper .card-number-label.card-number-label-focus {
    color: var(--textGrey) !important;
    top: calc(50% - 3px);
    font-size: 14px;
    font-weight: 400;
    line-height: 14.52px;
    left: 14px;
    opacity: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    transform: translateY(-10%) !important;
}

.react-datepicker .react-datepicker__navigation .react-datepicker__navigation-icon {
    position: relative;
    text-indent: -9999px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: #101828;
    justify-content: center;
    height: 18px;
    width: 18px;
}

.react-datepicker .react-datepicker__navigation .react-datepicker__navigation-icon::before {
    left: unset;
    top: unset;
    right: unset;
    bottom: unset;
    border-width: 2px 2px 0px 0;
    border-color: var(--textGreyColor);
    border-radius: 1px;
    margin-top: -2px;
}
.react-datepicker .react-datepicker__navigation .react-datepicker__navigation-icon--next::before {
    margin-left: -3px;
}

.react-datepicker {
    border: 0px solid var(--custom-loading-background-color) !important;
    z-index: 2;
    border-radius: 8px;
    background: var(--dropdown-background-color) !important;
    color: var(--text-more-dark) !important;
    border-radius: 8px !important;
    box-shadow: 0px 2.33333px 10px 0px rgba(146, 140, 151, 0.25);
    right: 8px !important;
}
.react-datepicker-popper {
    z-index: 1;
    transform: translate(0px, 33px) !important;
    width: 100%;
}
@media (min-width: 220px) and (max-width: 1024px) {
    .react-datepicker {
        top: 25px !important;
    }
}

.date-range-select .react-datepicker-popper {
    z-index: 1;
    transform: translate(0px, 22px) !important;
    width: 100%;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    font-size: 12px;
    font-weight: 400;
    color: #344054;
}
.react-datepicker--time-only {
    /* min-width: 130px; */
    width: 100% !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    border-radius: 10px !important;
    background: var(--white) !important;
    /* overflow: hidden; */
}
.react-datepicker__time-container {
    width: 100% !important;
}
.react-datepicker__header--time--only {
    display: none !important;
}

.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time {
    background: var(--white) !important;
    padding: 6px;
    border-radius: 10px !important;
    box-shadow: 0px 5.33333px 16px 0px rgba(146, 140, 151, 0.25);
    border: none !important;
}
.react-datepicker__time-container .react-datepicker__time {
    position: absolute !important;
    width: 100% !important;
}

.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    margin: 0;
    width: 100%;
    padding: 0 0;
}
.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list {
    padding: 0;
    height: 100%;
    max-height: 185px;
    border-radius: 12px !important;
    scrollbar-width: none !important;
}
.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item {
    text-align: left;
    margin: 2px 0;
    padding-right: 22px;
}
.react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box .react-datepicker__time-list .react-datepicker__time-list-item:hover {
    color: var(--primary);
    font-weight: normal;
    background: #f9fafb;
    border-radius: 6px;
    font-weight: 500;
}
.react-datepicker .react-datepicker__triangle::after {
    content: none !important;
}
.react-datepicker .react-datepicker__triangle::before {
    content: none !important;
}
.react-datepicker .react-datepicker__input-container {
    width: 100%;
}
.react-datepicker .react-datepicker__header {
    background-color: transparent;
}
.react-datepicker .react-datepicker__header:not(.react-datepicker__header--has-time-select) {
    border-bottom: none;
}
.react-datepicker .react-datepicker__header .react-datepicker__current-month {
    margin: 16px 0 12px;
    color: var(--text-more-dark);
    font-family: var(--brand-font-family);
    font-size: 14px;
    font-weight: 600;
}
.react-datepicker .react-datepicker__header {
    padding: 0px;
}
.react-datepicker .react-datepicker__navigation {
    top: 11px;
    border: 0px solid #f1f1f1;
    height: auto;
    width: 38px;
    margin: 0 1rem;
    border-radius: 8px;
    text-indent: unset;
}
.react-datepicker .react-datepicker__navigation .react-datepicker__navigation-icon {
    position: relative;
    text-indent: -9999px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--text-more-dark);
    justify-content: center;
}
.react-datepicker .react-datepicker__navigation .react-datepicker__navigation-icon::before {
    left: unset;
    top: unset;
    right: unset;
    bottom: unset;
    border-width: 2px 2px 0px 0;
    border-color: var(--textGreyColor);
    border-radius: 1px;
    margin-top: -2px;
}
.react-datepicker .react-datepicker__navigation .react-datepicker__navigation-icon--next::before {
    margin-left: -3px;
}
.react-datepicker .react-datepicker__day-names .react-datepicker__day-name {
    color: var(--text-more-dark);
    font-size: 12px;
    width: 35px;
    height: 35px;
    margin: 0;
    padding: 0.5rem;
}
.react-datepicker .react-datepicker__day {
    font-size: 12px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin: 0;
    padding: 0.25rem;
    color: var(--text-more-dark);
}
.react-datepicker .react-datepicker__day--keyboard-selected {
    background-color: var(--brand-color);
    color: var(--brand-text-color);
    position: relative;
    outline: none;
}
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
    background-color: var(--brand-color) !important;
    color: var(--brand-text-color);
}
.react-datepicker .react-datepicker__day--keyboard-selected:before {
    content: "";
    height: 5px;
    width: 5px;
    background-color: var(--brand-text-color);
    border-radius: 50%;
    position: absolute;
    top: 77%;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
}
.react-datepicker .react-datepicker__day--keyboard-selected::after,
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--range-end:after {
    content: "";
    position: absolute;
    background-color: var(--brand-color) !important;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 50%;
}

.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--selected {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
    position: relative;
    z-index: 2;
    color: var(--brand-text-color);
}
.react-datepicker .react-datepicker__day--in-selecting-range.react-datepicker__day--selected {
    background-color: var(--brand-color) !important;
}
.react-datepicker .react-datepicker__day--selecting-range-start {
    background-color: var(--brand-color) !important;
}
.react-datepicker .react-datepicker__day--in-range {
    background-color: var(--brand-color);
    border-radius: 0;
    color: var(--brand-text-color);
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    border-radius: 0.3rem;
    background-color: var(--brand-color);
    color: var(--text-more-dark);
}
.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
    color: var(--text-more-dark) !important;
    opacity: 0.6;
    background-color: transparent !important;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
    color: var(--text-more-dark);
    opacity: 0.6;
}

.react-datepicker .react-datepicker__day--selected {
    background-color: var(--brand-color);
    color: var(--brand-text-color);
    position: relative;
}
.react-datepicker__week {
    margin-bottom: 4px;
}
.react-datepicker__month-select,
.react-datepicker .react-datepicker__year-select {
    background: transparent;
}
.react-datepicker .react-datepicker__day--selected:before {
    content: "";
    height: 5px;
    width: 5px;
    background-color: var(--brand-text-color);
    border-radius: 50%;
    position: absolute;
    top: 77%;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
}
.react-datepicker .react-datepicker__month {
    margin: 1rem;
}

.react-datepicker .react-datepicker__day--in-range {
    background-color: #f2f4f7;
    border-radius: 0;
    color: #344054;
}
.react-datepicker .react-datepicker__day--in-selecting-range {
    background-color: #f2f4f7;
}

.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--keyboard-selected {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
    position: relative;
    z-index: 2;
    color: #fff;
}
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--selected,
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--range-end {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
    position: relative;
    z-index: 2;
    color: #fff;
}
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--selected:after,
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--range-end:after {
    content: "";
    position: absolute;
    background-color: #3570f8;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 50%;
}

.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--selected::before,
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--range-end::before {
    content: "";
    height: 5px;
    width: 5px;
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    top: 77%;
    left: 50%;
    z-index: 2;
    transform: translateX(-50%);
}
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--range-end.react-datepicker__day--selected {
    border-radius: 50%;
}
.react-datepicker .react-datepicker__day--in-range.react-datepicker__day--range-end {
    border-radius: 0;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    background-color: var(--brand-color-LightDark) !important;
    border-radius: 50% !important;
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    border-radius: 0.3rem;
    background-color: var(--brand-color);
    border-radius: 50% !important;
    color: var(--brand-text-color);
}

.react-datepicker__navigation-icon.react-datepicker__navigation-icon--next {
    height: 33px !important;
    width: 18px !important;
    font-size: 0px !important;
}
.react-datepicker__navigation-icon.react-datepicker__navigation-icon--previous,
.react-datepicker__navigation-icon--previous {
    height: 33px !important;
    width: 18px !important;
    font-size: 0px !important;
}
.react-datepicker__navigation-icon--previous:before {
    font-size: 0px !important;
}

.phone-body-bg,
.phone-body-bg * {
    box-sizing: border-box;
}
.phone-body-bg {
    background: var(--step-content-background-color);
    border-radius: 8px;
    padding: 0px 16px 20px 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    height: 320px;
    position: relative;
    overflow: hidden;
}
.phone-body-bg .phone-body {
    background: #ffffff;
    border-radius: 0px 0px 36px 36px;
    border-style: solid;
    border-color: #000000;
    border-width: 0px 6px 6px 6px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
    align-self: stretch;
    flex: 1;
    position: relative;
    overflow: hidden;
}
.phone-body-bg .frame-1171278336 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}
.phone-body-bg .url-bar-mini {
    background: var(--browser-ui-dark-bg, rgba(61, 61, 61, 0.94));
    padding: 7px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    gap: 0.77px;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
    box-shadow: inset 0px 0.25px 0px 0px rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(7.68px);
    border-radius: 0 0 30px 30px;
}
.phone-body-bg .searchbar {
    padding: 0px 12px 0px 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    position: relative;
}
.phone-body-bg .search-bottom {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 9.22px;
    padding: 9px 11px 9px 11px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    flex-shrink: 0;
    height: 33.79px;
    position: relative;
    overflow: hidden;
}
.phone-body-bg .a-a {
    flex-shrink: 0;
    width: 14.36px;
    height: 9.47px;
    position: relative;
    overflow: visible;
}
.phone-body-bg .domain {
    display: flex;
    flex-direction: row;
    gap: 3.07px;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
}
.phone-body-bg .domain .div {
    color: #ffffff;
    text-align: left;
    font-size: 10.751999855041504px;
    font-weight: 400;
    opacity: 0.5;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.phone-body-bg .domain2 {
    color: #ffffff;
    text-align: left;
    font-size: 12.288000106811523px;
    letter-spacing: -0.027999999999999997em;
    font-weight: 400;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.phone-body-bg .reload {
    flex-shrink: 0;
    width: 11.33px;
    height: 13.81px;
    position: relative;
    overflow: visible;
}
.phone-body-bg .toolbar {
    padding: 0px 14px 0px 14px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    height: 37.77px;
    position: relative;
}
.phone-body-bg .symbol2 {
    flex-shrink: 0;
    position: relative;
    overflow: visible;
}
.phone-body-bg .home-bar-mini {
    flex-shrink: 0;
    width: 288px;
    height: 26.11px;
    position: relative;
}
.phone-body-bg .home-indicator {
    background: #ffffff;
    border-radius: 76.8px;
    width: 102.14px;
    height: 3.84px;
    position: absolute;
    left: 50%;
    translate: -50%;
    bottom: 6.26px;
}

.truncate-custom {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.multiline-truncate-wrapper {
    display: inline-block;
    box-sizing: border-box;
    white-space: pre-line;
    word-wrap: break-word;
}

.multiline-truncate-wrapper .maltiline-truncate {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
}
.line-truncate {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    word-break: break-word;
    white-space: normal;
    max-width: 284px;
}
.Toastify__toast-icon {
    width: auto !important;
}
.phone-revarse-body-bg,
.phone-revarse-body-bg * {
    box-sizing: border-box;
}
.phone-revarse-body-bg {
    background: var(--step-content-background-color);
    border-radius: 8px;
    padding: 20px 16px 0px 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    height: 320px;
    position: relative;
    overflow: hidden;
}
.phone-revarse-body-bg .phone-body {
    background: var(--step-content-background-color);
    border-radius: 36px 36px 0px 0px;
    border-style: solid;
    border-color: #000000;
    border-width: 6px 6px 0px 6px;
    padding: 6px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex: 1;
    position: relative;
    overflow: hidden;
}

.phone-right-body-bg,
.phone-right-body-bg * {
    box-sizing: border-box;
}
.phone-right-body-bg {
    background: var(--step-content-background-color);
    border-radius: 8px;
    padding: 0px 0px 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    height: 320px;
    position: relative !important;
    overflow: hidden;
}
.phone-right-body-bg .phone-body {
    background: var(--step-content-background-color);
    border-radius: 0px 0px 0px 36px;
    border-style: solid;
    border-color: #000000;
    border-width: 0px 0px 6px 6px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex: 1;
    position: relative !important;
    overflow: hidden;
}

.phone-front-body-bg,
.phone-front-body-bg * {
    box-sizing: border-box;
}
.phone-front-body-bg {
    background: var(--step-content-background-color);
    border-radius: 8px;
    padding: 0px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    align-self: stretch;
    flex-shrink: 0;
    height: 320px;
    position: relative !important;
    overflow: hidden;
}
.phone-front-body-bg .phone-body {
    background: #808080;
    border-radius: 0px 0px 0px 0px;
    border-style: solid;
    border-color: #000000;
    border-width: 0px 6px 0px 6px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex: 1;
    position: relative !important;
    overflow: hidden;
}
@media (min-width: 240px) and (max-width: 1023px) {
    .professionals_section .select_box_main {
        width: 100%;
        min-width: 100%;
    }
}
/* .font-16px {
    font-size: 16px !important;
}
.font-18px {
    font-size: 18px !important;
}
.font-20px {
    font-size: 20px !important;
}
.font-22px {
    font-size: 22px !important;
}
.font-24px {
    font-size: 24px !important;
}
.font-26px {
    font-size: 26px !important;
}
.font-28px {
    font-size: 28px !important;
}
.font-30px {
    font-size: 30px !important;
} */

.custom-loading {
    position: relative;
    /* margin-bottom: 1px;
    border: 0;
    */
    visibility: hidden;
}
.custom-loading:after {
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    background-color: var(--custom-loading-background-color);
    border-radius: inherit;
    visibility: visible;
}
.custom-loading.loading-dark:after {
    background-color: #d1d5db;
}
.custom-loading.image-loading:after {
    background-image: url("../../assets/svgs/image-loading.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 25% 25%;
}
.custom-loading.user-loading:after {
    background-image: url("../../assets/svgs/user-loading.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
}
.custom-loading.graph-loading {
    visibility: visible;
}
.custom-loading.graph-loading text {
    visibility: hidden;
}
.custom-loading.graph-loading svg {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.custom-loading.graph-loading svg path {
    fill: var(--custom-loading-background-color);
    visibility: visible;
    filter: none;
}
.custom-loading.graph-loading:after {
    background-color: transparent;
}

.capitalize-first::first-letter {
    text-transform: uppercase;
}

@keyframes pulse {
    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }
}

@media only screen and (max-width: 480px) {
    .Toastify__toast-container--top-left,
    .Toastify__toast-container--top-center,
    .Toastify__toast-container--top-right {
        top: 23px !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
    }
}

/* .isExpandadtext span {
    display: unset;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.01em;
    line-height: 19.4px;
} */

.isExpandadtextnot span {
    display: unset !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 19.4px;
    letter-spacing: -0.01em;
}
.isExpandadtext span {
    @apply !text-[14px] !leading-none font-medium tracking-[-0.01em] text-[#7C869A];
}
.show-more-less-clickable #more {
    position: relative;
    line-height: 18.4px;
    padding-bottom: 3px;
    transform: translateY(1px);
}
.show-more-less-clickable #less {
    position: relative;
    line-height: 19.4px;
}
.show-more-less-clickable:hover {
    color: var(--brand-color) !important;
    transition: transform 0.3s;
}
.isExpandadtextnot .show-more-less-clickable #less:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
    height: 1px;
}
.isExpandadtextnot .show-more-less-clickable #less:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: -1px;
    left: 0;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s;
    background-color: var(--main-text-color);
}
.isExpandadtextnot .show-more-less-clickable #more:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
    height: 1px;
}
.isExpandadtextnot .show-more-less-clickable #more:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 1px;
    left: 0;
    transform: scaleX(0);
    transform-origin: bottom right;
    transition: transform 0.3s;
    background-color: var(--main-text-color);
}
.mobile_datepiker .react-datepicker {
    top: 50px !important;
    left: 50%;
    transform: translate(-50%, -3%);
    z-index: 9999;
    width: fit-content;
}
.disableDateAndTime {
    opacity: 0.5;
}
.dateAndTimeOpacity .disableDateAndTime {
    opacity: 1 !important;
}

.customOverlayPanel {
    /* position: fixed !important; */
    top: auto !important;
    right: 77px !important;
    left: auto !important;
    bottom: 224px !important;
    transform: translate(0%, 0%) !important;
    z-index: 999 !important;
    width: auto !important;
    max-width: 310px !important; /* Adjust width as needed */
    border-radius: 9px !important;
    box-shadow: 0px 4px 6px -2px #10182808;
    box-shadow: 0px 12px 16px -4px var(--promotion-overlay-shadow);
}
@media (max-width: 1024px) {
    .customOverlayPanel {
        z-index: 99999 !important;
        right: 12px !important;
        bottom: 164px !important;
    }
}

.promotionOverlay::before {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 76%;
    transform: translateX(-50%) rotate(222deg);
    width: 14px;
    height: 14px;
    background: var(--dropdown-background-color);
    border-left: 1px solid var(--border-color-primary);
    border-top: 1px solid var(--border-color-primary);
    z-index: 99;
}
.custom-range {
    -webkit-appearance: none;
    width: 100%;
    height: 6px;
    border-radius: 5px;
    background: #e5e7eb; /* Light gray track */
    outline: none;
    transition: 0.3s;
}

/* Progress bar before the thumb */
.custom-range::-webkit-slider-runnable-track {
    height: 6px;
    border-radius: 5px;
    background: #e5e7eb;
}

/* Thumb (handle) */
.custom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: white;
    border: 3px solid #2563eb; /* Blue border */
    cursor: pointer;
    margin-top: -5px;
}

.custom-range::-moz-range-thumb {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: white;
    border: 3px solid #2563eb;
    cursor: pointer;
}

/* To show progress before the thumb */
.custom-range::-moz-range-progress {
    background: #2563eb;
    height: 6px;
    border-radius: 5px;
}

/* Style for progress in Webkit browsers */
.custom-range::-webkit-slider-runnable-track {
    background: linear-gradient(to right, #2563eb 50%, #e5e7eb 50%);
}

.appSwiper .swiper {
    width: 100%;
    height: 100%;
}
@media (min-width: 280px) and (max-width: 1023px) {
    .Toastify__toast-container {
        max-width: 320px;
        right: 16px !important;
        margin-left: auto !important;
        bottom: 16px !important;
    }
}
.Toastify__toast-container--bottom-right {
    max-width: 370px !important;
    right: 52px !important;
    padding: 0px !important;
    margin-left: auto !important;
    bottom: 38px !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loading_02 {
    animation: rotate 1.6s linear infinite;
}
.hover-filled-opacity {
    position: relative;
    display: inline-block;
    width: auto;
    height: auto;
    background-color: transparent;
    /* border: 1px solid var(--brand-color); */
    color: var(--brand-text-color);
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    height: 30px;
    padding: 2px 18px;
    transition: 0.2s 0.1s;
    --tw-shadow: 0px 0px 10px rgba(var(--brand-navigation-border-color-hover), 0.5);
    --tw-shadow-colored: 0px 0px 10px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    border-radius: var(--brand-border-radius-minimal);
    line-height: 30px !important;
}

.hover-filled-opacity::before {
    position: absolute;
    content: "";
    background-color: var(--brand-color);
    transition: 0.3s ease-out;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    z-index: -1;
    border-radius: var(--brand-border-radius-minimal);
}

.hover-filled-opacity:hover::before {
    opacity: 0;
}

.hover-filled-opacity:hover {
    color: var(--brand-color);
}
