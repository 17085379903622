@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .btn_outline_primary {
        @apply border border-headerBorderColor px-4 py-1 rounded-secondary transition-all ease-in-out delay-100 font-semibold text-sm h-[44px];
    }
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    .ba_form_control {
        @apply w-full rounded-md bg-cardColor px-3.5 py-2.5 h-[44px]  placeholder:text-textGrey text-sm border border-lightgray focus-visible:ring-0 items-center;
    }
    .btn_secondary_black {
        @apply border border-primary text-txtcolor px-10 hover:bg-contentBackground hover:text-txtcolor text-base bg-contentBackground focus:!ring-transparent;
    }
    .btn_disable_black {
        @apply border border-lightgray text-textGrey px-10 text-disableLightGreyText text-sm bg-disableLightGreyButton focus:!ring-transparent shadow-[0_1px_1px_0px_#1018280D];
    }
    .btn-appointment-primary {
        @apply btn_outline_primary h-[36px] text-sm lg:hover:text-primary capitalize !border !border-primaryAppointment text-primary px-3;
    }

    .form_input_control {
        @apply focus:shadow-[0px_8px_14px_-4px_var(--input-shadow)] focus-within:shadow-[0px_8px_14px_-4px_var(--input-shadow)] focus-visible:shadow-[0px_8px_14px_-4px_var(--input-shadow)] relative !outline-0 outline-none focus:ring-0 focus:ring-transparent border border-borderCounter hover:!border-primaryBorderHover focus:!border-primaryBorderHover focus-within:!border-primaryBorderHover focus-visible:!border-primaryBorderHover;
    }

    .search-box .form_input_control {
        @apply !shadow-[0px_1px_2px_0px_rgba(16,24,40,0.05)] focus:!shadow-[0_1px_2px_0px_var(--border-color-hover),0_0px_0px_4px_var(--brand-color-hover)] focus-within:!shadow-[0_1px_2px_0px_var(--border-color-hover),0_0px_0px_4px_var(--brand-color-hover)] focus-visible:!shadow-[0_1px_2px_0px_var(--border-color-hover),0_0px_0px_4px_var(--brand-color-hover)] border !border-borderCounter hover:!border-primaryBorderHover focus:!border-primaryBorderHover focus-within:!border-primaryBorderHover focus-visible:!border-primaryBorderHover !transition-all !duration-[0.3s] !outline-0;
    }

    .is-invalid {
        @apply relative !outline-0 outline-none focus:ring-0 focus:ring-transparent border !border-danger hover:!border-danger focus:!border-danger focus-within:!border-danger focus-visible:!border-danger;
    }

    .is-invalid {
        @apply !border !border-danger focus:!border-danger focus-within:!border-danger focus-visible:!border-danger;
    }

    .text-error {
        @apply text-danger text-xs pl-3 leading-[150%] mt-[6px];
    }
    .custom-paragraph-text {
        @apply text-base md:text-sm lg:text-base xl:text-lg xl:leading-[27px] 2xl:text-xl uppercase md:capitalize;
    }
    .custom-heading {
        @apply text-[40px] leading-[50px] xl:text-[62px] xl:leading-[75.03px] font-normal text-TextColor;
    }
    /* steper css start */
    .fwpo__steper {
        @apply mx-auto flex items-center justify-between;
    }

    .step__round {
        @apply flex bg-white h-4 w-4 shrink-0 items-center justify-center rounded-full text-inputbg p-1;
    }

    .custom-icon-border {
        @apply border-lightgray;
    }

    .steper_text {
        @apply absolute top-0 mt-6 w-max lg:w-56  text-center text-textGrey text-xs font-medium;
    }

    .border_start {
        @apply flex-auto border-t-2;
    }

    .step_completed .step__round {
        @apply bg-primary text-white p-[3px];
    }

    .step_completed .steper_text {
        @apply text-primary;
    }

    .step_completed .border_start {
        @apply border-primary;
    }

    .step_inprogress .step__round {
        @apply bg-primary text-white p-1;
    }

    .step_inprogress .steper_text {
        @apply text-primary;
    }

    /* steper css end */

    .fl-input-field {
        @apply w-full rounded-secondary flex items-center h-[44px];
    }

    .fl-input-field.fl-phone-field .fl-flag {
        @apply flex items-center pl-[14px] pr-[14px] flex-shrink-0 grow-0  basis-[76px] h-[44px] box-border cursor-pointer border border-transparent;
    }

    .fl-input-field.fl-phone-field .fl-flag {
        @apply active:border active:border-primary focus:border focus:border-primary outline-0 ring-0;
    }
    .fl-input-field.fl-phone-field .fl-flag.fl-flag-disabled {
        @apply active:border-transparent focus:border-transparent;
    }

    .fl-input-field.fl-phone-field .fl-flag .icon-angel {
        @apply ml-2 grow-0 shrink-0 basis-4;
    }
    .fl-field-title-label {
        @apply font-medium mb-2 block leading-[140%]  text-txtcolor;
    }
    .modal_backdrop {
        @apply fixed inset-0 z-[1102];
    }
    .modal_content {
        @apply fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full max-h-full flex items-center justify-center;
    }
    .country-code-dropdown-list {
        @apply text-sm text-txtAppointmentColor overflow-auto  scrollbar-hide flex flex-col gap-[2px] max-h-[155px] md:max-h-[174px];
    }
    .country-code-dropdown-list > li {
        @apply px-3 py-2 text-textMoreGrey cursor-pointer text-[14px] font-medium flex justify-between items-center rounded-minimal;
        transition: all 0.3s ease;
    }
    .country-code-dropdown-list > li:hover,
    .country-code-dropdown-list > li.active {
        @apply text-[14px] text-textMoreDark bg-mainBackgroundColor font-medium;
    }
    .select_box_main .select__menu {
        @apply shadow-[0px_5.33px_16px_0px_#0000001A] bg-dropdownBackgroundColor border border-borderColorPrimary rounded-minimal;
    }
    /* .country-code-dropdown-list > li {
        @apply px-3 py-2 text-textMoreGrey cursor-pointer text-[14px] font-medium flex justify-between items-center rounded-secondary;
        transition: all 0.3s ease;
    }
    .country-code-dropdown-list > li:hover,
    .country-code-dropdown-list > li.active {
        @apply text-[14px] text-textMoreDark bg-[#f1f1f1] font-medium;
    } */
    .radio-container {
        display: flex;
        align-items: center;
    }

    .radio-container input[type="radio"] {
        display: none;
    }

    .radio-container label {
        padding: 5px;
        border: 1px solid var(--button-color);
        border-radius: 5px;
        margin-right: 10px;
        cursor: pointer;
    }

    .checkmark {
        display: inline-block;
        width: 16px;
        height: 16px;
        /* margin-right: 5px; */
        background-color: var(--content-background-color);
        border: 1px solid var(--brand-color);
        border-radius: 50%;
        cursor: pointer;
    }

    .radio-container input[type="radio"]:checked ~ .checkmark:after {
        content: "";
        position: absolute;
        display: block;
        width: 16px;
        height: 16px;
        margin: -1px;
        background-color: var(--content-background-color);
        border: 4px solid var(--brand-color);
        border-radius: 50%;
        cursor: pointer;
    }

    .radio-container input[type="number"] {
        width: 50px;
        margin-right: 10px;
    }

    .radio-container input[type="color"] {
        margin-right: 10px;
    }
    .input_text {
        @apply border-none border-b outline focus:border-none focus-within:border-none active:border-none border-b-templateCardBorderColor outline-0 outline-transparent focus:outline-none;
    }
    .title_text {
        @apply text-[34px] md:text-[34px] lg:text-[38px] xl:text-[40px] 2xl:text-[42px] text-txtcolor font-normal leading-[100%] uppercase;
    }
    .sub_text {
        @apply text-txtcolor font-normal text-[15px] 2xl:text-[16px] leading-[150%] block;
    }
    /* template 2 layout */
    .layout-columns {
        display: grid;
        grid-template-columns: 1fr 1fr;
        position: relative;
    }
    .layout-home-column-media {
        width: 50% !important;
        grid-column: 1 / span 2;
        grid-row: 1;
        height: calc(100vh - 60px);
        position: sticky;
        top: 0px;
        transition: width 1.4s cubic-bezier(0.4, 0, 0.2, 1);
        z-index: 5;
        background-color: var(--black);
        color: var(--white);
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        text-transform: uppercase;
    }
    .layout-home-column-content {
        grid-area: 1 / 2;
        overflow: hidden;
        padding-top: var(--header-height);
        background: var(--template-background-color);
        position: relative;
    }

    @media (min-width: 240px) and (max-width: 767px) {
        .layout-columns {
            display: grid;
            grid-template-columns: 1fr;
            position: relative;
        }
        .layout-home-column-media {
            min-height: 480px;
            width: 100% !important;
            grid-column: 1;
            grid-row: 1;
            height: calc(100vh - 150px);
            position: relative;
            top: 0px;
            transition: width 1.4s cubic-bezier(0.4, 0, 0.2, 1);
            z-index: 5;
            background-color: var(--black);
            color: var(--white);
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
        }
        .layout-home-column-content {
            grid-area: auto;
            overflow: hidden;
            padding-top: var(--header-height);
            background: var(--template-background-color);
            position: relative;
        }
        .menu-column {
            grid-column: span 3;
        }
        .newsletter-area {
            grid-column: span 6;
        }
        .design-grid {
            grid-row-gap: 60px;
            column-gap: 15px;
            grid-template-columns: repeat(6, 1fr);
        }
    }

    @media (min-width: 768px) and (max-width: 1279px) {
    }

    @media (min-width: 1280px) and (max-width: 1439px) {
    }

    @media (min-width: 1440px) {
    }

    @media (min-width: 1921px) {
    }
    .slice-label {
        display: flex;
        align-items: center;
        gap: 6px;
    }
    .slice-label .num {
        border: 1.5px solid var(--template-text-color);
        height: 22px;
        width: 22px;
        min-width: 22px;
        line-height: 22px;
        font-weight: 600;
        border-radius: 100%;
        font-size: 12px;
        padding-top: 1px;
        color: var(--template-text-color);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .slice-label .slice-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 21.78px;
        color: var(--template-text-color);
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .design-grid {
        column-gap: 30px;
        display: grid;
        grid-template-columns: repeat(12, 1fr);
    }
    .menu-column {
        grid-column: span 2;
    }
    .newsletter-area {
        display: flex;
        flex-direction: column;
        grid-column: 9 / span 4;
        overflow: hidden;
        text-align: left;
    }
    @media (min-width: 240px) and (max-width: 767px) {
        .menu-column {
            grid-column: span 3;
        }
        .newsletter-area {
            grid-column: span 6;
        }
        .design-grid {
            grid-row-gap: 60px;
            column-gap: 15px;
            grid-template-columns: repeat(6, 1fr);
        }
        .main-section-spaching {
            padding: 20px 16px !important;
            margin-bottom: 4px !important;
        }
    }

    .prismic-image.fill-space,
    .prismic-image.fill-space .image-sizer {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
    .spacing-top {
        @apply pt-[54px] xl:pt-[78px];
    }
    .footer-title {
        @apply font-medium text-[12px] leading-[12px] text-[#696969] mb-[10px];
    }
    .footer-sub-title {
        @apply text-TextColor text-[14px] leading-[21px] font-normal -tracking-[0.02em] block cursor-pointer uppercase hover:text-primary;
    }
    .main-section-spaching {
        @apply py-[24px] px-[30px] mb-[46px];
    }
    .p-inputotp .p-inputotp-input,
    .p-inputotp .p-inputtext,
    .p-inputotp .p-component {
        @apply !w-[87px] !h-[87px] border-transparent rounded-secondary bg-inputBg transition p-4 text-4xl text-black font-medium text-center focus:outline-0 focus:ring-2 focus:ring-primary !important;
    }
    .p-inputotp-input {
        width: 100px !important;
        height: 100px;
    }
    .location-title-text {
        @apply text-txtAppointmentColor font-semibold text-[20px] leading-[24.2px] xls:text-[28px] -tracking-[0.02em] xls:leading-[35.6px];
    }
    .location-sub-text {
        @apply text-textGrey font-normal text-[14px] lg:text-base mt-[2px] lg:mt-[3px] leading-[19.6px] lg:leading-[21.24px] -tracking-[0.01rem];
    }
    .card-element {
        @apply !text-base py-[10px] px-3.5 rounded-secondary placeholder:text-textGrey placeholder:text-base border border-borderCounter focus:ring-0 focus:border-lightgray focus:ring-offset-0 focus-visible:outline-none w-full placeholder:justify-items-center;
    }
    /* .search-input {
        @apply shadow-sm focus:!shadow-[0_1px_2px_0px_rgba(16,24,40,0.05),0_0px_0px_4px_rgba(224,234,255,1)] border border-borderPrimary focus:border-utilityBrand200 transition-all duration-[0.3s] outline-0;
    }
    .form_control_hover {
        @apply placeholder:text-gray-500 font-medium text-grayLight900 border focus:shadow rounded-lg text-xs px-3 py-2 bg-white w-full hover:border-grayText !outline-0 outline-none focus:ring-0 focus:ring-transparent;
    } */

    .location-marker-image {
        width: 26px;
        height: 26px;
        background: var(--map-pin-background-color);
        background-size: contain;
        display: flex;
        border-radius: 6px 6px 3px 3px;
        justify-content: center;
    }
    .location-marker-image::after {
        position: absolute;
        content: "";
        width: 0px;
        height: 0px;
        bottom: -25.5px;
        border-radius: 10px;
        border: 16px solid transparent;
        border-top: 14px solid var(--map-pin-background-color);
    }
    .location-marker-secondary-image {
        width: 30px;
        height: 31px;
        padding: 3px;
        background: var(--map-pin-background-color);
        background-size: contain;
        display: flex;
        border-radius: 10px 10px 6px 6px;
        justify-content: center;
    }
    /* .location-marker-secondary-image:hover {
        opacity: 0 !important;
        cursor: none;
        transition: 0.2s ease all !important;
        -moz-transition: 0.2s ease all !important;
        -webkit-transition: 0.2s ease all !important;
        visibility: hidden !important;
    } */
    /* .location-marker-image:hover {
        opacity: 0 !important;
        cursor: none;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    } */

    /* .mapboxgl-marker:hover {
        opacity: 0 !important;
    }
    .parent-marker:hover {
        opacity: 0 !important;
    } */
    .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
        border-bottom-color: var(--card-background-color) !important;
    }
    .mapboxgl-popup-tip {
        align-self: center;
        border-bottom-color: var(--card-background-color) !important;
        border-top: none;
    }
    .mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
        align-self: center;
        border-bottom-color: var(--card-background-color) !important;
        border-top: none;
    }
    .map-box-wrapper {
        background-color: var(--card-background-color);
    }
    .location-marker-secondary-image::after {
        position: absolute;
        content: "";
        width: 0px;
        height: 0px;
        bottom: -25.5px;
        border-radius: 10px;
        border: 16px solid transparent;
        border-top: 14px solid var(--map-pin-background-color);
    }
    .marker {
        background-size: cover;
        width: 24px;
        z-index: 9;
        height: 24px;
        cursor: pointer;
        margin-top: 1px;
        border-radius: 5px;
        border: 1px solid var(--map-pin-border-color) !important;
    }

    @import "./mapBox.css";
    @import "./PersonalTemplate1.css";
    @import "./LoginFlow.css";

    .shadow-card {
        box-shadow: 0px 2px 33px 0px rgba(0, 0, 0, 0.05);
    }
    .mobile_card_drawer {
        box-shadow: 0px 4px 80px 0px rgba(0, 0, 0, 0.15);
    }
    .card-shadow {
        box-shadow: 0 2px 16px 0 var(--shadow-background-color);
    }

    .CloseShadow:hover {
        box-shadow: 0px 5px 10px var(--custom-button-shadow);
    }

    .sidebar_shadow {
        box-shadow: var(--sidebar-shadow);
    }

    @media (max-width: 1024px) {
        .CustomButtonShadowMax {
            box-shadow: 0px 1px 2px 0px var(--custom-button-shadow);
        }
    }
    .CustomButtonShadow {
        box-shadow: 0px 1px 2px 0px var(--custom-button-shadow);
    }
    .button_shadow {
        box-shadow: 0px 1px 2px 0px var(--custom-button-shadow);
    }

    @media (min-width: 1024px) {
        .dateTimeShadow:hover {
            box-shadow: var(--date-time-shadow);
        }
    }
    @media (min-width: 768px) {
        .card-shadow-specialist:hover {
            box-shadow: 0 2px 16px 0 var(--shadow-background-color);
        }
        .card-shadow-service:hover {
            box-shadow: 0px 2px 10px 0px var(--shadow-background-color);
        }
        .date-and-time-shadow:hover {
            box-shadow: 0px 2px 12px 0px var(--shadow-background-color);
        }
        .card-shadow-hover:hover {
            box-shadow: 0 2px 16px 0 var(--shadow-background-color);
        }
    }
    .modelGallery {
        background-color: transparent !important;
    }
    .modal_backdrop {
        z-index: 99999 !important;
        margin: 0px !important;
        background-color: rgba(17, 24, 39, var(--model-backdrop-opacity)) !important;
    }
}
